<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        In the chemical systems shown below, solid lines represent covalent bonds while dotted lines
        represent non-covalent interactions. Which of the depictions of molecules show a
        <span class="text-bold">hydrogen bonding</span> interaction. Check all systems that apply.
      </p>

      <div v-for="option in options1" :key="option.value" class="pl-8">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block my-0 py-0"
          :value="option.value"
        />
        <stemble-latex :content="option.text" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question58',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options1: [
        {text: '$\\,\\,\\,\\,\\ce{H-H}$', value: '1'},
        {text: '$\\ce{-N\\cdots\\cdot\\cdot H-O}-$', value: '2'},
        {text: '$\\ce{-C\\cdots\\cdot\\cdot H-F}$', value: '3'},
        {text: '$\\ce{-O\\cdots\\cdot\\cdot H-C}-$', value: '4'},
      ],
    };
  },
};
</script>
